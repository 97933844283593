import { Button, CssBaseline, Stack, TextField, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { FormEvent } from 'react';
import LoginWrapper from '../login/LoginWrapper';
import Logo from '../utilities/Logo';
import { AppContext } from '../utilities/StateProvider';

interface DevStagePasswordCheckPropsI {
	stage: 'dev' | 'stage' | 'prod';
	setPasswordEntered: React.Dispatch<React.SetStateAction<boolean>>;
}

const DevStagePasswordCheck = ({ stage, setPasswordEntered }: DevStagePasswordCheckPropsI) => {
	const Main = styled('main')({
		flexGrow: 1,
	});

	const { setGlobalAlertMessage } = React.useContext(AppContext);

	const [password, setPassword] = React.useState('');

	const correctPassword = 'asdf1234';

	const handleSubmit = (event: FormEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (password === correctPassword) {
			localStorage.setItem('passwordEntered', 'true');
			setGlobalAlertMessage({ message: 'Welcome, worthy developer' });
			setPasswordEntered(true);
		} else {
			setGlobalAlertMessage({ message: 'Password incorrect. Please contact hello@deepar.ai for help.', severity: 'error' });
		}
	};

	return (
		<div>
			<Main>
				<CssBaseline />
				<LoginWrapper>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							padding: 4,
							width: '100%',
						}}
					>
						<Stack sx={{ mw: '200px', textAlign: 'center', mb: 2 }} direction='column' alignItems='center'>
							<Logo sx={{ mw: 3 }} />
							<img src='/login-icons.png' alt='Login icons showing DeepAR face filters' style={{ maxWidth: '100%', width: '240px', marginTop: '0.5rem' }} />
						</Stack>
						<Box sx={{ mt: 1, textAlign: 'center', width: '100%' }} component='form' onSubmit={handleSubmit}>
							<Typography variant='h3'>Enter password for {stage} </Typography>
							<TextField
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								margin='normal'
								required
								fullWidth
								id='devstagepass'
								label={`${stage} password`}
								name='devstagepass'
								autoFocus
								type='password'
								sx={{ marginBottom: 2 }}
							/>
							<Button fullWidth variant='contained' type='submit'>
								Submit
							</Button>
						</Box>
					</Box>
				</LoginWrapper>
			</Main>
		</div>
	);
};

export default DevStagePasswordCheck;
